import React from 'react';
import { HeadingElements, HEADING_VARIANTS } from '@belong/types';
import useAnalyticsEvents from '@belong/providers/analyticsEvents';
import { buttonClick } from '@belong/analytics';
import { Button } from '../Button';
import * as Strip from './ActionStrip.styles';
import { IActionStrip } from './ActionStrip.types';
import { getAsPropFromVariant, Heading } from '../../styles/Typography/Heading';
import { ACTION_STRIP } from '../../../helpers/testIds';

export const ActionStrip: React.FC<IActionStrip> = ({ heading, link, headingLevel }: IActionStrip) => {
  let headingElement: string;
  const headingVariant = HEADING_VARIANTS.medium;
  const analyticsEvents = useAnalyticsEvents();

  if (headingLevel) {
    headingElement = `h${headingLevel}`;
  } else {
    headingElement = getAsPropFromVariant({ variant: headingVariant });
  }

  return (
    <Strip.Wrapper data-testid={ACTION_STRIP.ROOT}>
      <Heading data-testid={ACTION_STRIP.TITLE} as={headingElement as HeadingElements} variant={headingVariant}>
        {heading}
      </Heading>
      <Button
        {...link}
        width={{ xs: 'full', md: 'default' }}
        data-testid={link.label}
        onClick={() => {
          analyticsEvents.send({
            ...buttonClick({
              label: 'action-strip-button',
              text: link.label
            }),
            component: 'action strip'
          });
        }}
      >
        {link.label}
      </Button>
    </Strip.Wrapper>
  );
};

ActionStrip.displayName = 'ActionStrip';
