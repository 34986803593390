import { COLOURS, media, SHADOWS } from '@belong/themes';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 3.2rem 1.6rem;
  box-shadow: ${SHADOWS.AMBIENT_LIGHT};
  background: ${COLOURS.WHITE};
  justify-content: center;
  align-items: center;
  text-align: center;

  button,
  a {
    margin-top: 1.6rem;
    flex: 0 0 auto;
  }

  ${media('md')`
    padding: 4rem;
    flex-direction: row;
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.2);

    button,
    a {
        margin-left: 2.4rem;
        margin-top: 0;
    }
  `}
`;
